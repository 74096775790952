import React, { Component } from 'react';
import HeaderComponent from '../header/headerComponent';
import Popup from 'reactjs-popup';
import $, { data } from "jquery";
import { JQUERYLABELS, KNOWN_ALLERGIES_GETLIST, KNOWN_EXISTING_CONDITIONS, LOADER_RED, HOMECARE_CURRENT_MEDITATION, HOMECARE_ALLERGY_DROPDOWN, HOMECARE_SAVE_ALLERGY, HOMECARE_EXISTING_CONDITION_DROPDOWN, HOMECARE_SAVE_CONDITION, HOMECARE_CURRENT_MEDITATION_EDIT, HOMECARE_UPDATE_ALLERGY, HOMECARE_UPDATE_EXISITING_CONDITION } from "../../utils/constant";
import allergyDanger from "../../assets/images/allergies-danger.png";
import allergyWarning from "../../assets/images/allergies-warning.png";
import allergyPrimary from "../../assets/images/allergies-primary.png";
import LS_SERVICE from "../../utils/localStorage";
import axios from 'axios';
import ToastComponent from "../../components/toast/toast";
import HcSubHeaderComponent from '../sub-header-hc/hcsubHeaderComponent';
import { AsyncPaginate } from 'react-select-async-paginate';
import icEdit from "../../assets/images/ic-edit-copy-7.svg";

const ALLERGY_TYPES = { 0: "Drug", 1: "Food", 2: "Others" };
const ALLERGY_INTENSITY_OBJECT = { 0: "Low", 1: "Moderate", 2: "Severe" };
const ALLERGY_OBSERVED_BY = { 0: "Observed by Hospital", 1: "Informed by Patient" };
const EXISTING_CONDITION_DURATION_TYPE = { 0: "Days", 1: "Weeks", 2: "Months", 3: "Years" };
const EXISTING_CONDITION_ON_MEDITATION_TYPE = { 1: "Yes", 0: "No" };

export default class AllergiesTab extends Component {

    state = {
        addAllergyPopup: false,
        addConditionPopup: false,
        allergiesTab: "Food",
        knownAllergiesData: [],
        existingConditionData: [],
        currentMeditation: "",
        pageDataLoaded: false,
        subHeaderData: null,
        active_route: "",
        is_doctor: false,
        toastSuccessClass: "bg-danger",
        toast: false,
        toastmsg: "",
        allergy_observedby: "0",
        allergy_type: "0",
        allergypage: 1,
        allergyValArray: [],
        keyword: "",
        allergy_value: null,
        selectedAllergyId: "",
        allergyIntensity: "0",
        booking_id: null,
        selectedOption: "",
        existing_condition_text: "",
        existing_condition_duration: "",
        existing_condition_id: "",
        existing_condition_id_value: "",
        existing_condition_days: "0",
        existing_condition_medication_name: "",
        existing_condition_on_meditation: "1",
        existing_medication_name: "",
        existing_condition_dropdown_values: [],
        inputEditHasData: false,
        allergy_name: '',
        allergy_refId: 0,
        allergy_comment: " ", //we don't have an option to edit, but in update api params it's required
        allergy_reaction: ""
    }

    constructor(props) {
        super(props);
        if (props.location.state === undefined) {
            props.history.push("/patient-list");
            return;
        }

        this.asyncPageRef = React.createRef();
    }

    handleProgressBtn = () => {
        this.props.history.push("/progress-notes");
    }

    UNSAFE_componentWillMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");
        this.setState({
            booking_id: this.props.location.state.booking_id,
            subHeaderData: this.props.location.state.subHeaderData,
            active_route: this.props.location.state.active_route,
            is_doctor: LS_SERVICE.get('is_doctor')
        })
    }

    componentDidMount() {
        const fields = $(".emrfrm .form-control");
        fields.each((i, e) => {
            JQUERYLABELS(e);
        });

        $(document).on("focusin", ".emrfrm .form-control", function () {
            JQUERYLABELS(this);
        });

        const { is_doctor, booking_id } = this.state;

        const KNOWN_ALLERGIES = axios.get(KNOWN_ALLERGIES_GETLIST({ is_doctor, booking_id }));
        const EXISTING_CONDITIONS = axios.get(KNOWN_EXISTING_CONDITIONS({ is_doctor, booking_id }));
        const CURRENT_MEDITATION = axios.get(HOMECARE_CURRENT_MEDITATION({ is_doctor, booking_id }));
        const EXISTING_CONDITION_DD_VALUES = axios.get(HOMECARE_EXISTING_CONDITION_DROPDOWN({ is_doctor }));

        Promise.all([KNOWN_ALLERGIES, EXISTING_CONDITIONS, CURRENT_MEDITATION, EXISTING_CONDITION_DD_VALUES])
            .then((response) => {
                let { knownAllergiesData, existingConditionData, currentMeditation, existing_condition_dropdown_values } = this.state;

                knownAllergiesData = response[0].data.known_allergies;
                existingConditionData = response[1].data.pre_existing_condition_details;
                currentMeditation = response[2].data.current_meditations !== null ? response[2].data.current_meditations.meditations_details : "";
                existing_condition_dropdown_values = response[3].data.conditions;

                knownAllergiesData = [...knownAllergiesData.drug, ...knownAllergiesData.environment, ...knownAllergiesData.food];

                this.setState({
                    knownAllergiesData,
                    existingConditionData,
                    currentMeditation,
                    existing_condition_dropdown_values,
                    pageDataLoaded: true
                })
            }).catch(error => {
                this.setState({
                    pageDataLoaded: true
                })
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
            })
    }

    handleOnChangeAllergyValues = (e) => {
        this.setState({
            allergy_value: e
        })
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }


    handleAllergyUpdate = () => {
        const { booking_id, allergy_refId, allergyIntensity, allergy_reaction, allergy_observedby, allergy_comment, is_doctor } = this.state

        console.log(allergy_reaction.length)
        if (allergy_reaction.length === 0) {
            this.resetToastState(() => this.setState({
                toastSuccessClass: "bg-danger",
                toast: true,
                toastmsg: "All fields are required",
            }))
            return;
        }

        console.log(allergy_refId)

        const PARAMS = {
            "ref_id": allergy_refId,
            "intensity": allergyIntensity,
            "source": allergy_observedby,
            "reaction": allergy_reaction,
            "comment": allergy_comment,
            "booking_id": booking_id
        }

        axios.post(HOMECARE_UPDATE_ALLERGY({ is_doctor }), PARAMS)
            .then((Response) => {
                let newData = Response.data.data.known_allergies;

                newData = [...newData.drug, ...newData.environment, ...newData.food];


                this.setState({
                    knownAllergiesData: newData,
                    addAllergyPopup: false,
                })
                this.resetToastState(() => this.setState({
                    toastSuccessClass: "bg-success",
                    toast: true,
                    toastmsg: Response.data.message,
                }))
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors.length > 0) {
                    this.resetToastState(() => this.setState({
                        toastSuccessClass: "bg-danger",
                        toast: true,
                        toastmsg: error.response.data.errors[0].message,
                    }))
                }
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
            });
    }

    handleAllergySubmit = () => {

        const { booking_id, allergy_type, allergyIntensity, allergy_observedby, allergy_reaction, allergy_value, is_doctor } = this.state;

        if (allergy_reaction.length === 0 || allergy_value === null) {
            this.resetToastState(() => this.setState({
                toastSuccessClass: "bg-danger",
                toast: true,
                toastmsg: "All fields are required",
            }))
            return;
        }

        const PARAMS = {
            "allergies": [
                {
                    "id": allergy_value.value, // allergy selected from drop down ID
                    "type": allergy_type, // DRUG, FOOD, OTHERS
                    "status": true,
                    "intensity": allergyIntensity,
                    "source": allergy_observedby,
                    "reaction": allergy_reaction
                },
            ],
            booking_id
        }

        axios.post(HOMECARE_SAVE_ALLERGY({ is_doctor }), PARAMS)
            .then((Response) => {

                let newData = Response.data.data.known_allergies;
                newData = [...newData.drug, ...newData.environment, ...newData.food];


                this.setState({
                    knownAllergiesData: newData,
                    addAllergyPopup: false,
                })
                this.resetToastState(() => this.setState({
                    toastSuccessClass: "bg-success",
                    toast: true,
                    toastmsg: Response.data.message,
                }))
                this.handleAllergyTabs(null, this.state.allergy_type);
            })
            .catch((error) => {
                if (error && error.response && error.response.data.errors || error.response.data.message) {
                    this.resetToastState(() => this.setState({
                        toastSuccessClass: "bg-danger",
                        toast: true,
                        toastmsg: error.response.data.message ?? error.response.data.errors[0].message,
                    }))
                }
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
                console.log(error.response)
            });
    }

    handleClickAllergyDropVal = (e) => {
        this.setState({
            allergyValArray: [],
            selectedAllergyId: e.target.dataset.allergyid,
            keyword: e.target.dataset.allergyname
        })
    }

    handleOnChangeExistingCondition = (e, updateVar = false) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        }, () => {
            if (updateVar) {
                let { existing_condition_dropdown_values, existing_condition_id, existing_condition_id_value } = this.state;
                existing_condition_id_value = existing_condition_dropdown_values.find(e => e.condition_id == existing_condition_id).condition_name;
                this.setState({
                    existing_condition_id_value
                })
            }
        })
    }

    handleExistingConditionUpdate = () => {
        const { booking_id, existingCondition_ref_id, existing_condition_id, existing_condition_duration, existing_condition_days,
            existing_medication_name, existing_condition_on_meditation, existing_condition_comments, subHeaderData, is_doctor } = this.state

        if (+existing_condition_days === 3 && existing_condition_duration > subHeaderData.dob || +existing_condition_days === 2 && existing_condition_duration > subHeaderData.dob * 12 || +existing_condition_days === 1 && existing_condition_duration > subHeaderData.dob * 52) {
            this.resetToastState(() => this.setState({
                toastSuccessClass: "bg-danger",
                toast: true,
                toastmsg: "Duration should be less than patient age",
            }))
            return;
        }

        const PARAMS = {
            "id": existingCondition_ref_id,
            "condition_id": existing_condition_id,
            "duration": existing_condition_duration,
            "duration_type": existing_condition_days,
            "on_medication": existing_condition_on_meditation,
            "medicine_name": existing_medication_name,
            "comments": existing_condition_comments,
            "booking_id": booking_id
        }

        axios.post(HOMECARE_UPDATE_EXISITING_CONDITION({ is_doctor }), PARAMS)
            .then((res) => {
                let newData = res.data.data.pre_existing_condition_details;

                this.setState({
                    existingConditionData: [...newData],
                    addConditionPopup: false,
                })
                this.resetToastState(() => this.setState({
                    toastSuccessClass: "bg-success",
                    toast: true,
                    toastmsg: res.data.message,
                }))
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors.length > 0) {
                    this.resetToastState(() => this.setState({
                        toastSuccessClass: "bg-danger",
                        toast: true,
                        toastmsg: error.response.data.errors[0].message,
                    }))
                }
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
            });
    }

    handleExistingConditionSubmit = () => {
        const { booking_id, existing_condition_id, existing_condition_days, existing_condition_id_value, existing_condition_duration,
            existing_condition_on_meditation, existing_condition_text, existing_medication_name, subHeaderData, is_doctor } = this.state;

        if (+existing_condition_days === 3 && existing_condition_duration > subHeaderData.dob || +existing_condition_days === 2 && existing_condition_duration > subHeaderData.dob * 12 || +existing_condition_days === 1 && existing_condition_duration > subHeaderData.dob * 52) {
            this.resetToastState(() => this.setState({
                toastSuccessClass: "bg-danger",
                toast: true,
                toastmsg: "Duration should be less than patient age",
            }))
            return;
        }



        const PARAMS = {
            "condition": [
                {
                    "condition_id": existing_condition_id,
                    "duration": existing_condition_duration,
                    "duration_type": existing_condition_days,
                    "on_medication": existing_condition_on_meditation,
                    "medicine_name": existing_medication_name,
                    "comments": existing_condition_text
                }
            ],
            "booking_id": booking_id
        }

        axios.post(HOMECARE_SAVE_CONDITION({ is_doctor }), PARAMS)
            .then((Response) => {
                let newData = Response.data.data.pre_existing_condition_details;

                this.setState({
                    existingConditionData: [...newData],
                    addConditionPopup: false,
                })
                this.resetToastState(() => this.setState({
                    toastSuccessClass: "bg-success",
                    toast: true,
                    toastmsg: Response.data.message,
                }))

                this.handleExistingReset(null);
            })
            .catch((error) => {
                if (error && error.response && error.response.data.errors || error.response.data.message) {
                    this.resetToastState(() => this.setState({
                        toastSuccessClass: "bg-danger",
                        toast: true,
                        toastmsg: error.response.data.message ?? error.response.data.errors[0].message,
                    }))
                }
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
                console.log(error.response)
            });
    }

    handleAllergyDropDownSearch = async (search, prevOptions) => {

        let { allergy_type, allergypage, is_doctor } = this.state;

        if (!search && search.length < 3) {
            this.setState({
                allergypage: 1
            })
            return {
                options: [],
                hasMore: false
            };
        } else {

            try {
                const OBJ = {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': LS_SERVICE.get("authorization")
                    }
                }

                const response = await fetch(HOMECARE_ALLERGY_DROPDOWN({ is_doctor, allergy_type, allergypage, keyword: search }), OBJ)
                const responseJSON = await response.json();

                const data = responseJSON.allergies;
                const hasMore = responseJSON.pagination.links.next !== "";

                if (hasMore)
                    this.setState({
                        allergypage: this.state.allergypage + 1
                    })

                let newData = [];

                data.forEach(e => {
                    newData.push({
                        "value": e.id,
                        "label": e.name
                    })
                })

                return {
                    options: newData,
                    hasMore
                };
            } catch (err) {
                return {
                    options: [],
                    hasMore: false
                };
            }
        }
    };

    handleAllergyTabs = (e, allergy_type) => {
        if (e !== null)
            e.preventDefault();

        this.setState({
            allergy_type,
            allergypage: 1,
            allergy_value: null,
            allergy_reaction: "",
            allergyIntensity: "0",
            allergy_observedby: "0"
        })
    }

    handleExistingReset = (e) => {
        if (e !== null)
            e.preventDefault();

        this.setState({
            existing_condition_id: "",
            existing_condition_duration: "",
            existing_condition_text: "",
            existing_condition_id_value: "",
            existing_condition_days: "0",
            existing_condition_medication_name: "",
            existing_condition_on_meditation: "1",
            existing_medication_name: ""
        })
    }

    handleAllergyPopup = () => {
        this.setState({
            addAllergyPopup: false,
            inputEditHasData: false,
            allergy_type: "0"
        }, () => this.handleAllergyTabs(null, this.state.allergy_type))
    }

    handleExistingPopup = () => {
        this.setState({
            addConditionPopup: false,
            inputEditHasData: false,
        }, () => this.handleExistingReset(null))
    }

    handleCurrentMedicSubmit = (e) => {
        const { currentMeditation, booking_id, is_doctor } = this.state;

        if (currentMeditation === "") {
            this.resetToastState(() => this.setState({
                toastSuccessClass: "bg-danger",
                toast: true,
                toastmsg: "Current Medication can't be blank",
            }))
            return;
        }

        const PARAMS = {
            booking_id,
            meditations_details: currentMeditation
        }

        axios.post(HOMECARE_CURRENT_MEDITATION_EDIT({ is_doctor }), PARAMS)
            .then((Response) => {
                const { currentMeditation } = this.state;

                this.setState({
                    currentMeditation
                }, () => this.resetToastState(() => this.setState({
                    toastSuccessClass: "bg-success",
                    toast: true,
                    toastmsg: "Current Medication Saved",
                })))
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
            });
    }

    resetToastState = (cb = "") => {
        this.setState({
            toastSuccessClass: "bg-danger",
            toast: false,
            toastmsg: "",
        }, () => { if (cb !== "") cb() });
    };

    handleEditKnownAller = (e) => {
        e.preventDefault();
        const { knownAllergiesData } = this.state;

        const EditId = e.target.dataset.id;
        const editData = knownAllergiesData.filter(val => +EditId === val.ref_id)
        const Data = editData[0]
        console.log(Data)
        console.log(EditId)
        this.setState({
            allergy_type: Data.type,
            allergypage: 1,
            allergy_reaction: Data.reaction,
            allergyIntensity: Data.intensity.id,
            allergy_observedby: Data.source.id,
            allergy_name: { id: Data.id, label: Data.name },
            allergy_refId: Data.ref_id,
            allergy_comment: Data.comments,
            inputEditHasData: true,
            addAllergyPopup: true
        })

    }

    handleEditExistingCondition = (e) => {

        const { existingConditionData } = this.state
        const existingEditData = existingConditionData.filter(val => val.id == e.target.dataset.id)
        const Data = existingEditData[0]

        this.setState({
            existing_condition_id: Data.condition_id,
            existing_condition_duration: Data.duration,
            existing_condition_days: Data.duration_type.id,
            existing_condition_on_meditation: Data.on_medication.id,
            existing_medication_name: Data.medicine_name === null ? "" : Data.medicine_name,
            existingCondition_ref_id: Data.id,
            existing_condition_comments: Data.comments,
            inputEditHasData: true,
            addConditionPopup: true
        })

    }


    render() {

        const { subHeaderData, pageDataLoaded, knownAllergiesData, existingConditionData, inputEditHasData } = this.state;

        const foodAllergy = [];
        if (knownAllergiesData.length > 0) {
            knownAllergiesData.forEach((value, index) => {
                const IMGSRC = value.intensity.id === 0 ? allergyPrimary : value.intensity.id === 1 ? allergyWarning : allergyDanger;
                foodAllergy.push(
                    <div className="item-list mb-3 border-bottom" key={index}>
                        <div className="d-flex justify-content-between">
                            <h5 className="text-secondary mb-0">{ALLERGY_TYPES[value.type]}</h5>
                            {value.allergies_by.id !== 1 ? <img className="edit-img" onClick={this.handleEditKnownAller} data-id={value.ref_id} src={icEdit} alt="edit-icon" /> : " "}
                        </div>
                        <div className="item_description py-2">
                            <div className="item_name">
                                <p className="text-allergies-type">{value.name}</p>
                                <p className={value.source.id ? "text-danger" : "text-success"}>{value.source.value}</p>
                            </div>
                            <div className="item_reaction">
                                <div className="d-flex flex-row">
                                    <p className="mr-3">Reaction</p>
                                    <p className="text-dark reaction-text ml-5"><strong>{value.reaction}</strong></p>
                                </div>
                                <p><img src={IMGSRC} /></p>
                            </div>
                            {value.comments !== null ? (
                                <div className="item_comments d-flex justify-content-start">
                                    <p>Comments</p>
                                    <p className="text-dark word-wrap ml-5">{value.comments !== null ? value.comments : "No Comments"}</p>
                                </div>
                            ) : ""}
                        </div>
                    </div>
                )
            })
        } else {
            foodAllergy.push(
                <div className="item-list mb-5" key="1">
                    <h5 className="mb-0 w-100 text-center mt-5">No Known Allergies</h5>
                </div>
            )
        }

        const existingDataList = [];
        if (existingConditionData.length > 0) {
            existingConditionData.forEach((val, index) => {
                existingDataList.push(
                    <div className="item-list mb-5" key={index}>
                        <div className="d-flex justify-content-between">
                            <h6 className="mb-0">{val.condition_name}</h6>
                            {val.condition_by.id !== 1 ? <img className="edit-img" onClick={this.handleEditExistingCondition} data-id={val.id} src={icEdit} alt="edit-icon" /> : ""}
                        </div>
                        <div className="item_description pb-2 pt-1">
                            <p><span>Duration</span>:&nbsp;&nbsp;&nbsp;&nbsp;{val.duration} {val.duration_type.value}</p>
                            <p><span>On Medication</span>:&nbsp;&nbsp;&nbsp;&nbsp;{val.on_medication.value}</p>
                            {val.on_medication.id !== 0 ? (
                                <p><span>Medicine Name</span>:&nbsp;&nbsp;&nbsp;&nbsp;{val.medicine_name}</p>
                            ) : ""}
                            {val.comments !== null ? (
                                <p className="word-wrap-all"><span>Comments</span>:&nbsp;&nbsp;&nbsp;&nbsp;{val.comments}</p>
                            ) : ""}
                        </div>
                    </div>
                )
            })
        } else {
            existingDataList.push(
                <div className="item-list mb-5" key="1">
                    <h5 className="mb-0 w-100 text-center mt-5">No Known Existing Conditions</h5>
                </div>
            )
        }

        return (
            <>
                <HeaderComponent />
                <HcSubHeaderComponent data={subHeaderData} />
                <div className="container-fluid custom-container">
                    <div className="row m-0">
                        {pageDataLoaded ? (
                            <>
                                <div className={`col-4 p-0`}>
                                    <div className="allergies-col p-0">
                                        <h5 className="known-allergies-h5">KNOWN ALLERGIES</h5>
                                        <div className="known-allergies-data">
                                            {foodAllergy}
                                        </div>
                                        <div className="d-flex justify-content-around severity-label">
                                            <p>Severity</p>
                                            <p><img className="mb-1 mr-2 allergies-caution-img" src={allergyPrimary} />Low</p>
                                            <p><img className="mb-1 mr-2 allergies-caution-img" src={allergyWarning} />Moderate</p>
                                            <p><img className="mb-1 mr-2 allergies-caution-img" src={allergyDanger} />High</p>
                                        </div>
                                        <div className="btn_allergies overflow">
                                            <button className="btn btn-outline-secondary font-weight-bold float-bottom text-dark px-4 py-1"
                                                onClick={() => this.setState({ addAllergyPopup: true })}>ADD ALLERGY</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-4 p-0`}>
                                    <div className="allergies-col existing-con p-0">
                                        <h5 className="known-allergies-h5">EXISTING CONDITIONS</h5>
                                        <div className="known-allergies-data">
                                            {existingDataList}
                                        </div>
                                        <div className="btn_allergies overflow">
                                            <button className="btn btn-outline-secondary font-weight-bold float-bottom text-dark px-4 py-1"
                                                onClick={() => this.setState({ addConditionPopup: true })}>ADD CONDITION</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-4 p-0`}>
                                    <div className="allergies-col cur-medication p-0">
                                        <h5 className="known-allergies-h5">CURRENT MEDICATION</h5>
                                        <textarea className="text-feild1 custom known-allergies-data" placeholder="Enter medication here"
                                            name="currentMeditation"
                                            value={this.state.currentMeditation}
                                            onChange={this.handleInputChange}
                                        ></textarea>
                                        <div className="btn_allergies">
                                            <button className="btn btn-outline-secondary font-weight-bold px-5 py-1 text-dark"
                                                onClick={this.handleCurrentMedicSubmit}>SAVE</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                                <div
                                    className="col d-flex justify-content-center align-self-center mt-5"
                                    style={{
                                        visibility: `${!this.state.notesDataLoaded ? "visible" : "hidden"
                                            }`,
                                    }}
                                >
                                    {LOADER_RED}
                                </div>
                            )}
                    </div>
                </div>

                <Popup
                    className="homeCare-modal"
                    open={this.state.addConditionPopup}
                    onClose={this.handleExistingPopup}
                    modal
                >
                    <div className="popup px-3">
                        <h5 className=" mt-3 mb-4">{` ${inputEditHasData ? "Edit" : "Add"} Condition`}</h5>
                        <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={this.handleExistingPopup}>
                            &times;
                        </div>
                        <div className="content emrfrm">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group hasdata">
                                        <select
                                            className="form-control"
                                            name="existing_condition_id"
                                            value={this.state.existing_condition_id}
                                            onChange={(e) => this.handleOnChangeExistingCondition(e, true)}
                                            disabled={inputEditHasData}
                                        >
                                            <option value="">Select a condition</option>
                                            {this.state.pageDataLoaded && this.state.existing_condition_dropdown_values.length > 0 ? (
                                                this.state.existing_condition_dropdown_values.map((e, i) => (
                                                    <option key={e.condition_id} value={e.condition_id}>{e.condition_name}</option>
                                                ))
                                            ) : ""}
                                        </select>
                                        <label className="required" htmlFor="gender">
                                            Condition<span className="text-secondary">*</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className={`form-group ${inputEditHasData ? "hasdata" : ""}`}>
                                        <input type="text" placeholder="Search"
                                            className="form-control" autoComplete="off"
                                            name="existing_condition_duration"
                                            onChange={this.handleOnChangeExistingCondition}
                                            value={this.state.existing_condition_duration} />
                                        <label className="required" htmlFor="keyword">Duration<span className="text-secondary">*</span></label>
                                    </div>
                                </div>
                                <div className="col-12 d-flex flex-row my-4">
                                    <label className="radio patient-name mr-3">Days
                                        <input type="radio" name="existing_condition_days"
                                            checked={this.state.existing_condition_days == "0"}
                                            value="0" onChange={this.handleInputChange} />
                                        <span className="checkround homecare"></span>
                                    </label>
                                    <label className="radio patient-name mr-3">Weeks
                                        <input type="radio" name="existing_condition_days"
                                            checked={this.state.existing_condition_days == "1"}
                                            value="1" onChange={this.handleInputChange} />
                                        <span className="checkround homecare"></span>
                                    </label>
                                    <label className="radio patient-name mr-3">Months
                                        <input type="radio" name="existing_condition_days"
                                            checked={this.state.existing_condition_days == "2"}
                                            value="2" onChange={this.handleInputChange} />
                                        <span className="checkround homecare"></span>
                                    </label>
                                    <label className="radio patient-name mr-3">Years
                                        <input type="radio" name="existing_condition_days"
                                            checked={this.state.existing_condition_days == "3"}
                                            value="3" onChange={this.handleInputChange} />
                                        <span className="checkround homecare"></span>
                                    </label>
                                </div>
                                <p className="col-12 font-weight-bold text-dark">On Medication?<span className="text-secondary">*</span></p>
                                <div className="col-12 d-flex flex-row">
                                    <label className="radio patient-name mr-3">Yes
                                        <input type="radio" name="existing_condition_on_meditation"
                                            value="1"
                                            checked={this.state.existing_condition_on_meditation == "1"}
                                            onChange={this.handleInputChange} />
                                        <span className="checkround homecare"></span>
                                    </label>
                                    <label className="radio patient-name mr-3">No
                                        <input type="radio" name="existing_condition_on_meditation"
                                            value="0"
                                            checked={this.state.existing_condition_on_meditation == "0"}
                                            onChange={this.handleInputChange} />
                                        <span className="checkround homecare"></span>
                                    </label>
                                </div>
                                {this.state.existing_condition_on_meditation == "1" ? (<div className="col-12">
                                    <div className={`form-group ${inputEditHasData ? "hasdata" : ""}`}>
                                        <input type="text" placeholder="Search"
                                            className="form-control" autoComplete="off"
                                            name="existing_medication_name"
                                            onChange={this.handleOnChangeExistingCondition}
                                            value={this.state.existing_medication_name} />
                                        <label className="required" htmlFor="keyword">Medication name<span className="text-secondary">*</span></label>
                                    </div>
                                </div>) : ""}
                            </div>

                        </div>
                        <div className="text-center my-3">
                            {!inputEditHasData ? (
                                <button className="btn btn-primary mt-2 px-4 font-weight-bold"
                                    onClick={this.handleExistingConditionSubmit}>SAVE</button>
                            ) : (
                                    <button className="btn btn-primary mt-2 px-4 font-weight-bold"
                                        onClick={this.handleExistingConditionUpdate}>UPDATE</button>
                                )}
                        </div>
                    </div>
                </Popup>

                <Popup
                    className="homeCare-modal"
                    open={this.state.addAllergyPopup}
                    onClose={this.handleAllergyPopup}
                    modal
                >
                    <div className="popup px-3">
                        <h5 className=" my-3">{` ${inputEditHasData ? "Edit" : "Add"} Allergies`}</h5>
                        <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={this.handleAllergyPopup}>
                            &times;
                        </div>
                        <div className="content emrfrm">
                            <div className="row">
                                <div className="col-12">
                                    {inputEditHasData ? (
                                        <nav className="homeCare nav nav-tabs" role="tablist">
                                            <a href="#" role="tab"
                                                className={`nav-item nav-link ${this.state.allergy_type == "0" ? "active" : "d-none"}`}
                                                onClick={(e) => this.handleAllergyTabs(e, "0")}>
                                                Drugs
                                            </a>
                                            <a href="#" role="tab"
                                                className={`nav-item nav-link ${this.state.allergy_type == "1" ? "active mx-0" : "d-none"} `}
                                                onClick={(e) => this.handleAllergyTabs(e, "1")}>
                                                Food
                                            </a>
                                            <a href="#" role="tab"
                                                className={`nav-item nav-link ${this.state.allergy_type == "2" ? "active" : "d-none"}`}
                                                onClick={(e) => this.handleAllergyTabs(e, "2")}>
                                                Others
                                            </a>
                                        </nav>
                                    ) : (
                                            <nav className="homeCare nav nav-tabs" role="tablist">
                                                <a href="#" role="tab"
                                                    className={`nav-item nav-link ${this.state.allergy_type == "0" ? "active" : ""}`}
                                                    onClick={(e) => this.handleAllergyTabs(e, "0")}>
                                                    Drugs
                                            </a>
                                                <a href="#" role="tab"
                                                    className={`nav-item nav-link ${this.state.allergy_type == "1" ? "active" : ""} `}
                                                    onClick={(e) => this.handleAllergyTabs(e, "1")}>
                                                    Food
                                            </a>
                                                <a href="#" role="tab"
                                                    className={`nav-item nav-link ${this.state.allergy_type == "2" ? "active" : ""}`}
                                                    onClick={(e) => this.handleAllergyTabs(e, "2")}>
                                                    Others
                                            </a>
                                            </nav>
                                        )}

                                    <div className="tab-content">
                                        <div className="fade tab-pane active show">
                                            <div className="row">
                                                <div className="col-12" >
                                                    {inputEditHasData ? (
                                                        <div className={`form-group ${inputEditHasData ? "hasdata" : ""}`}>
                                                            <input type="text" id="last_name" name="allergy_name"
                                                                className="form-control" placeholder="Reactions" autoComplete="off"
                                                                value={this.state.allergy_name.label}
                                                                onChange={this.handleInputChange}
                                                                disabled />
                                                            <label className="required" htmlFor="last_name">{ALLERGY_TYPES[this.state.allergy_type]}<span className="text-secondary">*</span></label>
                                                        </div>
                                                    ) : (
                                                            <div className="form-group hasdata disable">
                                                                <AsyncPaginate
                                                                    value={this.state.allergy_value}
                                                                    loadOptions={this.handleAllergyDropDownSearch}
                                                                    onChange={this.handleOnChangeAllergyValues}
                                                                />
                                                                <label className="required" htmlFor="keyword">{ALLERGY_TYPES[this.state.allergy_type]}<span className="text-secondary">*</span></label>
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="col-12">
                                                    <div className={`form-group ${inputEditHasData ? "hasdata" : ""}`}>
                                                        <input type="text" id="last_name" name="allergy_reaction"
                                                            className="form-control" placeholder="Reactions" autoComplete="off"
                                                            value={this.state.allergy_reaction}
                                                            onChange={this.handleInputChange} />
                                                        <label className="required" htmlFor="last_name">Reaction<span className="text-secondary">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <p className="font-weight-bold text-dark">Intensity<span className="text-secondary">*</span></p>
                                                    <div className=" d-flex flex-row my-4">
                                                        <label className="radio patient-name homeCare mr-3">Low
                                                            <img src={allergyPrimary} alt="Low" />
                                                            <input type="radio" value="0"
                                                                name="allergyIntensity"
                                                                checked={this.state.allergyIntensity == "0"}
                                                                onChange={this.handleInputChange} />
                                                            <span className="checkround homecare"></span>
                                                        </label>
                                                        <label className="radio patient-name homeCare mr-3">Moderate
                                                            <img src={allergyWarning} alt="Moderate" />
                                                            <input type="radio" value="1"
                                                                name="allergyIntensity"
                                                                checked={this.state.allergyIntensity == "1"}
                                                                onChange={this.handleInputChange} />
                                                            <span className="checkround homecare"></span>
                                                        </label>
                                                        <label className="radio patient-name homeCare mr-3">Severe
                                                            <img src={allergyDanger} alt="Danger" />
                                                            <input type="radio" value="2"
                                                                name="allergyIntensity"
                                                                checked={this.state.allergyIntensity == "2"}
                                                                onChange={this.handleInputChange} />
                                                            <span className="checkround homecare"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <p className="font-weight-bold text-dark">Informed by?<span className="text-secondary">*</span></p>
                                                    <div className=" d-flex flex-row my-4">
                                                        <label className="radio patient-name mr-3">Observed by Hospital
                                                            <input type="radio" name="allergy_observedby"
                                                                value="0"
                                                                checked={this.state.allergy_observedby == "0"}
                                                                onChange={this.handleInputChange} />
                                                            <span className="checkround homecare"></span>
                                                        </label>
                                                        <label className="radio patient-name mr-3">Informed by Patient
                                                            <input type="radio" name="allergy_observedby"
                                                                value="1"
                                                                checked={this.state.allergy_observedby == "1"}
                                                                onChange={this.handleInputChange} />
                                                            <span className="checkround homecare"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center my-3">
                            {!inputEditHasData ? (
                                <button className="btn btn-primary mt-2 px-4 font-weight-bold"
                                    onClick={this.handleAllergySubmit}>SAVE</button>
                            ) : (
                                    <button className="btn btn-primary mt-2 px-4 font-weight-bold"
                                        onClick={this.handleAllergyUpdate}>UPDATE</button>
                                )}

                        </div>
                    </div>
                </Popup>

                <ToastComponent
                    successClass={this.state.toastSuccessClass}
                    show={this.state.toast}
                    message={this.state.toastmsg}
                />

            </>
        )
    }
}
