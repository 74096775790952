import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import Moment from "react-moment";
import bell_icon from "../../assets/images/bell.png";
import info_ic from "../../assets/images/ic-info.svg";
import {
  GETGENDER,
  UHID,
  HANDLE_PATIENT_VITALS_COLORS,
  TEMPERATURE_TYPE_VALIDATE_FUNCTION
} from "../../utils/constant";

class homeCareCard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleClickNav = (booking_id, call_today = 0) => {

    this.props.history.push({
      pathname: "/hc-consult",
      state: { booking_id: booking_id, homeCare: 1, call_today },
    });
  }

  // handleAlertClick = (booking_id) => {
  //   this.props.history.push({
  //     pathname: "/hc-consult",
  //     state: { booking_id: booking_id, homeCare: 1 },
  //   });
  // }

  render() {

    const appointments = this.props.appointments;
    const homecareCard = [];
    const today = new Date;
    // const features = this.props.appointments.test.features;

    if (appointments !== undefined && appointments.length > 0)
      appointments.forEach((value, index) => {
        homecareCard.push(
          <div className="card mb-3" key={index}>
            <div className="card-bodycustom card-body p-3">
              <div className="row">
                <div className="col-10">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="healtvc-card-title text-dark font-weight-bold mb-2 card-title h5">{value.patient.first_name
                      ? value.patient.first_name.toLowerCase()
                      : ""}{" "}
                      {value.patient.last_name
                        ? value.patient.last_name.toLowerCase()
                        : ""}{" "}
                      <span
                        className={
                          value.patient.dob === "0000-00-00" || value.patient.dob === null ? "hidden" : "text-age"
                        }
                      >
                        <Moment
                          diff={
                            value.patient.dob !== "0000-00-00"
                              ? value.patient.dob
                              : new Date()
                          }
                          unit="years"
                        >
                          {today}
                        </Moment>{" "}
                        Yrs,{" "}
                        {GETGENDER(value.patient.gender)}
                        {UHID(value.patient.uhid) !== '' ? ' | ' + value.patient.uhid : ''}
                        {value.patient.relation !== null ? ` | ${value.patient.relation}` : ""}</span>
                    </div>
                    <div className="healtvc-card-title text-dark font-weight-bold mb-2 card-title h5 d-flex flex-row align-items-center">
                      <div className="theme-text">{value.test.duration === 0 ? "New" : "Day " + value.test.days}{" "}</div>
                      <div className="text-age">&nbsp;| Package : {value.test.title} {value.test.duration} {value.test.duration == "1" ? "day" : "days"}</div>
                      {value.test.features.length > 0 ? (
                        <div className="ml-1 tooltip-img align-items-center justify-content-center">i</div>) : ""}
                      <div className="tooltip-text">
                        <span>
                          <p className="font-weight-bold mb-1">Package Inclusions :</p>
                          <ul className="pl-3 features-list">
                            {value.test.features.length > 0 ? (
                              value.test.features.map((e, i) => (
                                <li key={i}>{e}</li>
                              ))) : ""}
                          </ul>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 mt-1">
                      <div className="d-flex flex-row justify-content-between">
                        <div className={`latest-call ${value.patient_lastcall === null ? "visiblity_hidden" : ""}`}>
                          {value.patient_lastcall !== null ? (
                            <>
                              <span>Last Call</span><br />
                              <p className="theme-text mb-0">
                                <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                  {value.patient_lastcall}
                                </Moment>
                              </p>
                            </>
                          ) : ""}
                        </div>
                        <div className={`text-right latest-vital ${value.patient_latest_vitals.length > 0 ? "visible" : "hidden"}`}>
                          <span>Latest Vitals</span><br /><p className="theme-text mb-0">
                            {value.patient_latest_vitals.length > 0 ? (
                              <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                {value.patient_latest_vitals[0].date.split("T")[0] + value.patient_latest_vitals[0].date.split("T")[1]}
                              </Moment>
                            ) : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    {value.patient_latest_vitals.length ? (
                      <div className={`col-7 ${value.patient_latest_vitals.length > 0 ? "visible" : "hidden"}`}>
                        <div className="readings-size d-flex flex-row justify-content-end">
                          <div className="d-flex flex-row readings-gap">
                            <div className="readings">
                              <div className="border-text-readings small mb-0">Temp&nbsp;&nbsp;
                              <p className={`text-center pl-0 reading-value mb-0 ${HANDLE_PATIENT_VITALS_COLORS(value.patient_latest_vitals[0].temperature.temperature_status)}`}>{value.patient_latest_vitals[0].temperature.temperature_value ? value.patient_latest_vitals[0].temperature.temperature_value.replace(/['"]+/g, '') : '-'}</p>
                              </div>
                            </div>
                            <div>
                              <p className="small ml-2 mt-2">{TEMPERATURE_TYPE_VALIDATE_FUNCTION(value.patient_latest_vitals[0].temperature.temperature_type)}</p>
                            </div>
                          </div>
                          <div className="d-flex flex-row readings-gap">
                            <div className="readings">
                              <div className="border-text-readings small mb-0">SpO2&nbsp;&nbsp;
                              <p className={`text-center pl-0 reading-value mb-0 ${HANDLE_PATIENT_VITALS_COLORS(value.patient_latest_vitals[0].spo3.spo_status)}`}>{value.patient_latest_vitals[0].spo3.spo_value ? value.patient_latest_vitals[0].spo3.spo_value.replace(/['"]+/g, '') : '-'}</p>
                              </div>
                            </div>
                            <div>
                              <p className="small ml-2 mt-2">%</p>
                            </div>
                          </div>
                          <div className="d-flex flex-row readings-gap">
                            <div className="readings bp">
                              <div className="border-text-readings small mb-0">BP&nbsp;&nbsp;
                              </div>
                              <p className={`text-center pl-0 reading-value mb-0`}>
                                <span className={HANDLE_PATIENT_VITALS_COLORS(value.patient_latest_vitals[0].bp.bp_systolic_status)}>
                                  {value.patient_latest_vitals[0].bp.bp_systolic_value}
                                </span>/
                                  <span className={HANDLE_PATIENT_VITALS_COLORS(value.patient_latest_vitals[0].bp.bp_diastolic_status)}>
                                  {value.patient_latest_vitals[0].bp.bp_diastolic_value}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p className="small ml-2 mt-2">mmHg</p>
                            </div>
                          </div>
                          <div className="d-flex flex-row readings-gap">
                            <div className="readings">
                              <div className="border-text-readings small mb-0">Pulse&nbsp;&nbsp;
                               <p className={`text-center pl-0 reading-value mb-0 ${HANDLE_PATIENT_VITALS_COLORS(value.patient_latest_vitals[0].pulse.pulse_status)}`}>{value.patient_latest_vitals[0].pulse.pulse_value ? value.patient_latest_vitals[0].pulse.pulse_value.replace(/['"]+/g, '') : '-'}</p>
                              </div>
                            </div>
                            <div>
                              <p className="small ml-2 mt-2">bpm</p>
                            </div>
                          </div>
                          <div className="d-flex flex-row ">
                            <div className="readings">
                              <div className="border-text-readings small mb-0">RBS&nbsp;&nbsp;
                               <p className={`text-center pl-0 reading-value mb-0 ${HANDLE_PATIENT_VITALS_COLORS(value.patient_latest_vitals[0].rbs.rbs_status)}`}>{value.patient_latest_vitals[0].rbs.rbs_value ? value.patient_latest_vitals[0].rbs.rbs_value.replace(/['"]+/g, '') : '-'}</p>
                              </div>
                            </div>
                            <div>
                              <p className="small ml-2 mt-2">mg/dL</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : ""}
                  </div>
                </div>
                <div className="col-2">
                  <div className="text-right h-100 d-flex flex-column justify-content-end align-items-end">
                    <div className="alert-box-1 d-flex flex-row justify-content-end">
                      {value.patient_alert.status ? (
                        <span className="btn box-alert" onClick={() => this.handleClickNav(value.id)}>PATIENT ALERT</span>
                      ) : ""}
                      {/* <img src={bell_icon} alt="bell" />  */}
                    </div>
                    {value.is_call_patient.calling_day ? (
                      <Button
                        className="button-healthvc button-variant-red px-3 font-weight-bold mt-3"
                        onClick={() => this.handleClickNav(value.id, 1)}
                      >
                        CALL TODAY
                      </Button>
                    ) : (
                        <button
                          className="button-healthvc px-3 font-weight-bold mt-3 btn btn-outline-secondary"
                          onClick={() => this.handleClickNav(value.id)}
                        >
                          PATIENT DETAILS
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      });

    if (homecareCard.length === 0) {
      homecareCard.push(
        <Card className="mb-3" key={homecareCard.length}>
          <Card.Body>
            <Row>
              <Col>
                <Card.Title
                  className="text-center no-appointement mb-2"
                >
                  No homecare appointments today!
                </Card.Title>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    }

    return (
      <div>{homecareCard}</div>
    );
  }
}

export default withRouter(homeCareCard);